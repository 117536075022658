<template>
  <!-- full page background color -->
  <v-card fluid flat tile height="92vh" class="back mb-n15 pa-6 px-md-15">
    <!-- to adjust in different media screen -->
    <v-row v-if="loader">
      <v-col v-for="n in 5" :key="n" cols="12" sm="6" md="4" lg="3" xl="2">
        <v-skeleton-loader type="image, actions" />
      </v-col>
    </v-row>
    <v-row v-else>
      <!-- the add form card-->
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <!-- align everything to the center and justify center -->
        <v-card height="100%" flat class="cursor-pointer rounded d-flex align-center justify-center text-center"
          @click="$router.push({ name: 'New Gopages' })">
          <!-- plus and the text are in different line so another div -->
          <div class="text-center">
            <!-- element plus -->
            <div class="d-flex justify-center">
              <v-icon size="100">
                mdi-plus
              </v-icon>
            </div>
            Start a new form
          </div>
        </v-card>
      </v-col>
      <!-- the forms already-->
      <v-col v-for="(form, i) in forms" :key="i" cols="12" sm="6" md="4" lg="3" xl="2">
        <!-- align everything to the center and justify center -->
        <v-card height="100%" flat class="cursor-pointer rounded thumb white--text mb-5">
          <!-- cross btn to delete the form -->
          <v-card-title class="pa-0 ma-0">
            <v-spacer></v-spacer>
            <v-btn icon dark @click="removeForm(form, i)"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <!-- form name and desc -->
          <v-card-text class="text-center ">
            <p class="text-uppercase text-h5 font-weight-bold mb-1 white--text">
              {{ form.content[0].title }}
            </p>
            <!-- <p class="text-body-2 grey--text text--lighten-4 text-truncate">
              {{ form.content[0].subHeading }}
            </p> -->
          </v-card-text>
          <!-- edit button -->
          <v-card-actions class="d-flex justify-space-around ">
            <!-- when click this will redirect to the edit form with the id of the form -->
            <!-- Edit Button -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" fab v-on="on" @click="
                  $router.push({ name: 'New Gopages', params: { id: form.id } })
                  ">
                  <v-icon color="#9cc134">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <!-- Link Button -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" fab v-on="on" @click="
                  copyText(form)
                  ">
                  <v-icon color="#9cc134">mdi-link</v-icon>
                </v-btn>
              </template>
              <span>Copy Link</span>
            </v-tooltip>
            <!-- Delete Button -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab @click="removeForm(form, i)">
                  <v-icon color="#9cc134">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
  
<script>
export default {
  data() {
    return {
      forms: [], // collection of forms will be stored here
      loader: true, // to wait till the data is ready
    };
  },
  created() {
    // getting all the forms in this account at the time of creation of the page
    this.$http
      .get(
        `${this.$store.state.ENDPOINT}/integrateForms?filter[where][companyId]=${this.$store.state.companyDetails.id}`
      )
      .then((res) => {
        this.forms = res.data;
        this.loader = false;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    // removing the form
    async removeForm(form, index) {
      var flag = 0; // to store if the user confirms to delete the form
      // await as the next step depends to the user's choice
      await this.$swal({
        type: "warning",
        text: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonColor: "#31a848",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((yes) => {
        // user confirmation
        if (yes.value) {
          flag = 1;
        }
      });

      if (flag == 1) {
        // removing the forms from database
        this.$http
          .delete(`${this.$store.state.ENDPOINT}/integrateForms/${form.id}`)
          .then((res) => {
            this.$swal({
              type: "success",
              text: "Form is successfully deleted",
            });
          })
          .catch((err) => {
            console.log(err);
          });
        // removing the form from the array so that no refresh is needed
        this.forms.splice(index, 1);
      }
    },
    copyText(input) {
      let gopageLink = `https://enterprise.godial.cc/gopages/${input.id}`;
      navigator.clipboard.writeText(gopageLink);
    },
  },
};
</script>
<style>
.thumb:hover {
  background: #6d8823 !important;
}
</style>
  