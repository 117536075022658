var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"back mb-n15 pa-6 px-md-15",attrs:{"fluid":"","flat":"","tile":"","height":"92vh"}},[(_vm.loader)?_c('v-row',_vm._l((5),function(n){return _c('v-col',{key:n,attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-skeleton-loader',{attrs:{"type":"image, actions"}})],1)}),1):_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-card',{staticClass:"cursor-pointer rounded d-flex align-center justify-center text-center",attrs:{"height":"100%","flat":""},on:{"click":function($event){return _vm.$router.push({ name: 'New Gopages' })}}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"size":"100"}},[_vm._v(" mdi-plus ")])],1),_vm._v(" Start a new form ")])])],1),_vm._l((_vm.forms),function(form,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-card',{staticClass:"cursor-pointer rounded thumb white--text mb-5",attrs:{"height":"100%","flat":""}},[_c('v-card-title',{staticClass:"pa-0 ma-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.removeForm(form, i)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"text-center "},[_c('p',{staticClass:"text-uppercase text-h5 font-weight-bold mb-1 white--text"},[_vm._v(" "+_vm._s(form.content[0].title)+" ")])]),_c('v-card-actions',{staticClass:"d-flex justify-space-around "},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":""},on:{"click":function($event){return _vm.$router.push({ name: 'New Gopages', params: { id: form.id } })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#9cc134"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":""},on:{"click":function($event){return _vm.copyText(form)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#9cc134"}},[_vm._v("mdi-link")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy Link")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"fab":""},on:{"click":function($event){return _vm.removeForm(form, i)}}},[_c('v-icon',{attrs:{"color":"#9cc134"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }